export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorMore = (value, ranges) => {
  if (!ranges || !ranges[0]) {
    return true
  }
  return value > ranges[0]
}

export const validatorLess = (value, ranges) => {
  if (!ranges || !ranges[0]) {
    return true
  }
  return value < ranges[0]
}

export const validatorAfter = (value, ranges) => {
  if (!ranges || !ranges[0]) {
    return true
  }
  return value >= ranges[0]
}

export const validatorBefore = (value, ranges) => {
  if (!ranges || !ranges[0]) {
    return true
  }
  return value <= ranges[0]
}

export const validatorDateBetween = (value, ranges) => {
  if (!ranges || !ranges[0] || !ranges[1]) {
    return true
  }
  return value?.toString().split('T')[0] >= ranges[0]?.toString().split('T')[0]
  && value?.toString().split('T')[0] <= ranges[1]?.toString().split('T')[0]
}

export const validatorDateOverlapStart = (value, ranges) => {
  const currentIdx = ranges?.[0]
  const periodSet = ranges?.[1]
  const s1 = value?.toString().split('T')[0]
  const e1 = periodSet?.[currentIdx]?.endDate?.toString().split('T')[0]
  if (!e1 || periodSet.length < 2) { return true }

  // 입력된 기간들에 중복되는 기간인지 점검
  for (let periodIdx = 0; periodIdx < periodSet.length; periodIdx += 1) {
    if (currentIdx !== periodIdx) {
      const s2 = periodSet[periodIdx]?.startDate?.toString().split('T')[0]
      const e2 = periodSet[periodIdx]?.endDate?.toString().split('T')[0]

      // e1 이 유효하지 않은 경우 멈춤
      // periodSet에 유효한 startDate, endDate가 들어있지 않은 경우 멈춤
      if (!s2 || !e2) { return true }

      // 중복되는 경우 false 반환
      if (s1 < e2 !== e1 < s2) {
        // 기간 겹침
        return false
      }
      if (s1 === e2 || e1 === s2) {
        // 기간 겹침
        return false
      }
    }
  }

  // 기간 안겹침
  return true
}

export const validatorDateOverlapEnd = (value, ranges) => {
  const currentIdx = ranges?.[0]
  const periodSet = ranges?.[1]
  const s1 = periodSet?.[currentIdx]?.startDate?.toString().split('T')[0]
  const e1 = value?.toString().split('T')[0]

  if (!s1 || periodSet.length < 2) { return true }

  // 입력된 기간들에 중복되는 기간인지 점검
  for (let periodIdx = 0; periodIdx < periodSet.length; periodIdx += 1) {
    if (currentIdx !== periodIdx) {
      const s2 = periodSet?.[periodIdx]?.startDate?.toString().split('T')[0]
      const e2 = periodSet?.[periodIdx]?.endDate?.toString().split('T')[0]

      // s1 이 유효하지 않은 경우 멈춤
      // periodSet에 유효한 startDate, endDate가 들어있지 않은 경우 멈춤
      if (!s2 || !e2) { return true }

      // 중복되는 경우 false 반환
      if (s1 < e2 !== e1 < s2) {
        // 기간 겹침
        return false
      }
      if (s1 === e2 || e1 === s2) {
        // 기간 겹침
        return false
      }
    }
  }

  // 기간 안겹침
  return true
}

export const validatorNotEqual = (value, targetVal) => {
  if (value === targetVal[0]) {
    return false
  }
  return true
}
