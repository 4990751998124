import { extend, localize } from 'vee-validate'
import {
  required as ruleRequired,
  required_if as ruleRequiredIf,
  email as ruleEmail,
  min as ruleMin,
  max as ruleMax,
  confirmed as ruleConfirmed,
  regex as ruleRegex,
  between as ruleBetween,
  alpha as ruleAlpha,
  integer as ruleInteger,
  digits as ruleDigits,
  alpha_dash as ruleAlphaDash,
  alpha_num as ruleAlphaNum,
  length as ruleLength,
} from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorMore,
  validatorLess,
  validatorAfter,
  validatorBefore,
  validatorDateBetween,
  validatorDateOverlapStart,
  validatorDateOverlapEnd,
  validatorNotEqual,
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', {
  ...ruleRequired,
  message: '[{_field_}]는(은) 필수 입력입니다.',
})

export const requiredIf = extend('requiredIf', {
  ...ruleRequiredIf,
  message: '연관 표시된 입력 중, 최소 하나 이상 입력되어야 합니다.',
})

export const email = extend('email', ruleEmail)

export const min = extend('min', {
  ...ruleMin,
  message: (field, params) => `[${field}]는(은) 최소 ${params.length}자 이상 입력되어야 합니다.`,
})

export const max = extend('max', {
  ...ruleMax,
  message: (field, params) => `[${field}]는(은) 최대 ${params.length}자 이내로 입력되어야 합니다.`,
})

export const confirmed = extend('confirmed', ruleConfirmed)

export const regex = extend('regex', ruleRegex)

export const between = extend('between', {
  ...ruleBetween,
  message: '[{_field_}]는(은) 값의 범위(min ~ max)에서 벗어납니다.',
})

export const alpha = extend('alpha', ruleAlpha)

export const integer = extend('integer', ruleInteger)

export const digits = extend('digits', ruleDigits)

export const alphaDash = extend('alpha-dash', ruleAlphaDash)

export const alphaNum = extend('alpha-num', ruleAlphaNum)

export const length = extend('length', ruleLength)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

export const more = extend('more', {
  validate: validatorMore,
  message: '[{_field_}]의 값은 비교대상보다 커야 합니다.',
})

export const less = extend('less', {
  validate: validatorLess,
  message: '[{_field_}]의 값은 비교대상보다 작아야 합니다.',
})

export const after = extend('after', {
  validate: validatorAfter,
  message: '[{_field_}]의 값은 비교대상보다 이후여야 합니다.',
})

export const before = extend('before', {
  validate: validatorBefore,
  message: '[{_field_}]의 값은 비교대상보다 이전이어야 합니다.',
})

export const dateBetween = extend('dateBetween', {
  validate: validatorDateBetween,
  message: '[{_field_}]는(은) 유효한 날짜 범위(min ~ max)에서 벗어납니다.',
})

export const dateOverlapStart = extend('dateOverlapStart', {
  validate: validatorDateOverlapStart,
  message: '다른 구간과 중복됩니다.',
})

export const dateOverlapEnd = extend('dateOverlapEnd', {
  validate: validatorDateOverlapEnd,
  message: '다른 구간과 중복됩니다.',
})

export const notEqual = extend('notEqual', {
  validate: validatorNotEqual,
  message: '비교 대상 값과 동일할 수 없습니다.',
})

// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  ar: {
    messages: ar.messages,
    names: {
      email: 'البريد الإلكتروني',
      password: 'كلمة السر',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
})
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
